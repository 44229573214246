import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import s from './Video.module.scss';

const Video = ({url, playing, readyHandler, playingHandler, override}) => {
    const config = {
        youtube: {
            playerVars: {
                disablekb: 1,
                modestBranding: 1,
                rel: 0,
            },
        },
        vimeo: {
            playerOptions: {
                title: true,
                controls: true,
            },
        },
    };

    let props = {};
    if(readyHandler !== null) {
        props.onReady = readyHandler;
    }
    if(playingHandler !== null) {
        props.onBufferEnd = playingHandler;
    }
    return (
        <div className={s['Video']}>
            <ReactPlayer
                url={url}
                playing={playing}
                config={config}
                className={s['Video__Player']}
                width="100%"
                height="100%"
                {...props}
                {...override}
            />
        </div>
    );
};

Video.propTypes = {
    url: PropTypes.string.isRequired,
    playing: PropTypes.bool,
    readyHandler: PropTypes.func,
    playingHandler: PropTypes.func,
    override: PropTypes.object,
};

Video.defaultProps = {
    url: '',
    playing: false,
    readyHandler: null,
    playingHandler: null,
    override: {},
};

export default Video;
