import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import { AnimateUpDown } from 'Components/Animate';
import Video from 'Components/Video';
import s from './VideoChat.module.scss';

const VideoChat = ({video, chatUrl, chatHeight}) => {
    const {t} = useTranslation();

    const [showChat, setShowChat] = useState(false);

    if(_.isEmpty(video) && _.isEmpty(chatUrl)) {
        return null;
    }

    return (
        <div className={s['VideoChat']}>
            {!_.isEmpty(video) &&
                <div className={s['VideoChat__Video']}>
                    <Video {...video} />
                </div>
            }

            {!_.isEmpty(chatUrl) &&
                <div className={s['VideoChat__Chat']}>
                    <button
                        className={s['VideoChat__Button']}
                        onClick={() => setShowChat(true)}
                    >
                        {t('videoChat.showChat')}
                    </button>

                    <AnimateUpDown
                        isVisible={showChat}
                    >
                        <div className={s['VideoChat__Popup']}>
                            <button
                                className={s['VideoChat__Close']}
                                onClick={() => setShowChat(false)}
                            >
                                <span className="sr-only">{t('videoChat.hideChat')}</span>
                            </button>
                            <iframe src={chatUrl} height={chatHeight} />
                        </div>
                    </AnimateUpDown>
                </div>
            }
        </div>
    );
};

VideoChat.propTypes = {
    video: PropTypes.object,
    chatUrl: PropTypes.string,
    chatHeight: PropTypes.number,
};

VideoChat.defaultProps = {
    video: {},
    chatUrl: '',
    chatHeight: 200,
};

export default VideoChat;
