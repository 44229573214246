import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ParallaxProvider } from 'react-scroll-parallax';
import _ from 'lodash';
import logoDefault from 'Assets/img/arkitekturgalan.svg';
import logo2023 from 'Assets/img/arkitekturgalan-2023-vertical.svg';
import logo2024 from 'Assets/img/arkitekturgalan-2024.svg';
import Base from 'Layouts/Base';
import Hero from 'Components/Hero';
import Modules from 'Components/Modules';
import Image from 'Components/Image';
import VideoChat from 'Components/VideoChat';
import ParallaxShape from 'Components/ParallaxShape';
import ContentModule from 'Components/ContentModule';
import s from './LandingArkitekturgalan.module.scss';

const LandingArkitekturgalan = ({gifs, videoChat, hero, program, modules, year}) => {

    const y = [-30, 40].map((i) => i + '%');

    const heroImage = _.get(hero, 'image', {});
    if(_.isEmpty(heroImage)) {
        const logos = {
            default: {
                src: logoDefault.src,
                width: 464,
                height: 234,
            },
            2023: {
                src: logo2023.src,
                width: 1000,
                height: 1155,
            },
            2024: {
                src: logo2024.src,
                width: 575,
                height: 241,
            },
        };
        const logo = _.get(logos, year, logos['default']);

        hero = {...hero,
            image: {
                ...logo,
                alt: `Arkitekturgalan ${year}`,
            },
            isLogo: true,
        };
    }

    return (
        <div className={s['LandingArkitekturgalan']}>
            <div className={s['LandingArkitekturgalan__Header']}>
                <ParallaxProvider>
                    {!_.isEmpty(gifs) &&
                        <div className={s['LandingArkitekturgalan__Gifs']}>
                            <div className={s['LandingArkitekturgalan__Wrap']}>
                                {Object.keys(gifs).map((size, index) => {
                                    const image = gifs[size];
                                    if(_.isEmpty(image)) {
                                        return null;
                                    }
                                    const gifClasses = classNames(
                                        [s['LandingArkitekturgalan__Gif']],
                                        [s[`LandingArkitekturgalan__Gif--${size}`]],
                                    );
                                    return (
                                        <div className={gifClasses} key={index}>
                                            <Image
                                                {...image}
                                                srcset={[]}
                                                lazyLoad={false}
                                                priority={true}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }

                    {!_.isEmpty(videoChat) &&
                        <div className={s['LandingArkitekturgalan__Wrap']}>
                            <VideoChat {...videoChat} />
                        </div>
                    }

                    {!_.isEmpty(program) &&
                        <div className={s['LandingArkitekturgalan__Content']}>
                            <ContentModule {...program} />
                        </div>
                    }

                    <ParallaxShape
                        y={y}
                        side="Left"
                        size="Small"
                        modifier="ContentModule"
                    />
                </ParallaxProvider>
            </div>

            <Hero {...hero} />

            <Modules
                {...modules}
                forceSize="Small"
                extendLeftRightModules={['Highlights']}
            />
        </div>
    );
};

LandingArkitekturgalan.propTypes = {
    hero: PropTypes.object,
    videoChat: PropTypes.object,
    gifs: PropTypes.object,
    program: PropTypes.object,
    modules: PropTypes.object,
    year: PropTypes.string,
};

LandingArkitekturgalan.defaultProps = {
    hero: {},
    videoChat: {},
    gifs: {},
    program: {},
    modules: {},
    year: '2023',
};

export default Base(LandingArkitekturgalan);
